.verification-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.verification-header .profile-pic {
	margin-bottom: 48px;
}

.verification-header .title {
	font-size: 40px;
	line-height: 58px;
	color: var(--black);
	margin-bottom: 16px;
}

.verification-header .description {
	font-size: 24px;
	color: var(--dark-gray-text);
	margin-bottom: 6px;
}

.verification-header .email {
	font-size: 24px;
	color: var(--black);
	font-weight: 700;
	margin-bottom: 40px;
}

@media only screen and (max-width: 1080px) {
	.verification: {
		padding: 80px 175px 0px 175px;
	}

	.verification-header .profile-pic {
		margin-bottom: 24px;
	}

	.verification-header .mailbox {
		width: 96px;
		height: 96px;
	}

	.verification-header .title {
		font-size: 32px;
		line-height: 48px;
	}

	.verification-header .description {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.verification-header .email {
		font-size: 20px;
		margin-bottom: 32px;
	}
}

@media only screen and (max-width: 600px) {
	.verification: {
		padding: 40px 16px 0px 16px;
	}

	.verification-header .profile-pic {
		margin-bottom: 16px;
	}

	.verification-header .mailbox {
		width: 96px;
		height: 96px;
		width: 72px;
		height: 72px;
	}

	.verification-header .title {
		font-size: 20px;
		line-height: 150%;
		margin-bottom: 12px;
	}

	.verification-header .description {
		font-size: 14px;
		margin-bottom: 4px;
	}

	.verification-header .email {
		font-size: 14px;
		margin-bottom: 24px;
	}
}
