.listing-preapproval .price-container {
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .listing-preapproval .price-container {
    margin-top: 72px;
  }
}

@media only screen and (max-width: 600px) {
  .listing-preapproval .price-container {
    margin-top: 56px;
  }
}

