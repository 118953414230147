.custom-accordion-details .MuiAccordionDetails-root {
	padding: 0 24px 24px 0;
	display: block;
}

@media only screen and (max-width: 767px) {
  .custom-accordion-details .MuiAccordionDetails-root {
		padding: 0 22px 18px 0;
	}
}

@media only screen and (max-width: 600px) {
  .custom-accordion-details .MuiAccordionDetails-root {
		padding: 0 20px 12px 0;
	}
}