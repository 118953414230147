.parking-details-preapproval .checkbox-container {
  margin-top: 28px;
}

.parking-details-preapproval .checkbox-label {
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .parking-details-preapproval .title {
    margin-bottom: 40px;
  }

  .parking-details-preapproval .checkbox-container {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .parking-details-preapproval .checkbox-container {
    margin-top: 16px;
  }

  .parking-details-preapproval .title {
    margin-bottom: 24px;
  }
}