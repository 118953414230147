.error-preapproval .error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  
}

.error-preapproval .error-image {
  margin-bottom: 40px;
  width: 225px;
  height: 196px;
}

.error-preapproval .title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  display: inline-block;
  width: auto;
  color: var(--black);
  margin-bottom: 24px;
}

.error-preapproval .description {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: var(--dark-gray-text);
  width: 368px;
  margin-bottom: 56px;
}

.error-preapproval .button {
  width: 207px;
  font-size: 20px;
  margin-right: 0px;
}

@media only screen and (max-width: 767px) {
  .error-preapproval .error-container {
    width: 100vw;
    margin-top: 0px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
  
  .error-preapproval .error-image {
    margin-bottom: 56px;
    width: 280px;
    height: 243px;
  }
  
  .error-preapproval .title {
    margin-bottom: 24px;
  }
  
  .error-preapproval .description {
    margin-bottom: 56px;
  }

  .error-preapproval .button {
    height: 60px
  }
}

@media only screen and (max-width: 600px) {
  .error-preapproval .error-image {
    margin-bottom: 40px;
    width: 160px;
    height: 141px;
  }
  
  .error-preapproval .title {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  
  .error-preapproval .description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
    width: 272px;
  }

  .error-preapproval .button {
    width: 136px;
    height: 48px;
    font-size: 14px;
  }
}