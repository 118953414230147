.form-status-notice.success {
	color: var(--primary-color);
	background-color: #f8fefc;
	border-color: #2fa67f;
}

.form-status-notice .text {
	font-weight: 700;
	margin-bottom: 0px;
}

.form-status-notice .icon-container {
	position: relative;
	top: 6px;
}

.form-status-notice .icon-container svg {
	height: 20px;
}

@media only screen and (max-width: 1080px) {
  .form-status-notice .icon-container {
    top: 2px;
  }
}

@media only screen and (max-width: 600px) {
  .form-status-notice .icon-container {
    top: 0px;
  }
}
