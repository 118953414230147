.begin-preapproval .image {
	margin: auto;
	display: block;
	margin-bottom: 48px;
	width: 320px;
	height: 252px;
}

.begin-preapproval .title {
	font-weight: bold;
	font-size: 32px;
	line-height: 48px;
	color: var(--black);
	margin: auto;
	margin-bottom: 24px;
}

.begin-preapproval .title,
.begin-preapproval .buttons,
.begin-preapproval .button {
	max-width: 472px;
	width: 100%;
}

.begin-preapproval .buttons {
	margin: auto;
	display: flex;
	flex-direction: column;
}

.begin-preapproval .button {
	margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
	.begin-preapproval .image {
		width: 380px;
		height: 300px;
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: 600px) {

	.begin-preapproval .content {
		padding-top: 8px;
	}

	.begin-preapproval .image {
		width: 179.03px;
		height: 140px;
		margin-bottom: 32px;
	}

	.begin-preapproval .title {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 16px;
	}

	.begin-preapproval .button {
		margin-bottom: 16px;
	}
}