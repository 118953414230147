.summary-header {
	font-weight: 900;
	margin-bottom: 40px;
}

.summary-header .sub-header {
	color: var(--black);
	text-transform: uppercase;
	margin-bottom: 8px;
	font-size: 16px;
	line-height: 24px;
}

.summary-header .header {
	font-size: 56px;
	line-height: 68px;
	margin-bottom: 24px;
}

.summary-header .description {
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
	color: var(--dark-gray-text);
}

@media only screen and (max-width: 1080px) {
	.summary-header {
		margin-bottom: 32px;
		font-size: 44px;
		line-height: 57px;
	}

	.summary-header .sub-header {
		margin-bottom: 4px;
	}

	.summary-header .header {
		font-size: 44px;
		line-height: 57px;
	}

	.summary-header .description {
		font-size: 16px;
		line-height: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-header {
		margin-bottom: 24px;
	}

	.summary-header .sub-header {
		font-size: 12px;
		margin-bottom: 0px;
	}

	.summary-header .header {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 12px;
	}
}
