.summary-panel-toggle {
	padding: 18px;
	border-top: 1px solid var(--light-gray);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.summary-panel-toggle .toggle {
	font-size: 24px;
	color: var(--primary-color);
	padding-right: 10px;
	font-weight: 700;
}

.summary-panel-toggle svg {
	color: var(--primary-color);
	font-size: 30px;
}

@media only screen and (max-width: 1080px) {
	.summary-panel-toggle .toggle {
		font-size: 16px;
		padding-right: 5px;
	}

	.summary-panel-toggle svg {
		font-size: 20px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel-toggle .toggle {
		font-size: 14px;
	}

	.summary-panel-toggle svg {
		font-size: 17px;
	}
}
