.radio-button {
  position: relative;
  display: flex;
}

.radio-button label {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-gray-text);
  cursor: pointer;
  padding: 15px 24px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.radio-button input[type=radio] {
  display: none;
}

.radio-button label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 24px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 100%;
}

.radio-button input[type=radio]:checked + label {
  background: #E6F4F0;
}

.radio-button input[type=radio]:checked + label::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  border-radius: 100%;
}

@media only screen and (max-width: 767px) {
  .radio-button label {
    font-size: 16px;
    line-height: 26px;
  }

  .radio-button label::before {
    width: 20px;
    height: 20px;
    margin-right: 28px;
  }

  .radio-button input[type=radio]:checked + label::after {
    content: "";
    height: 14px;
    width: 14px;
    left: 27px;
  }
}

@media only screen and (max-width: 600px) {
  .radio-button label {
    font-size: 14px;
    line-height: 26px;
    padding: 9px 12px;
    vertical-align: middle;
    vertical-align: middle;
  }

  .radio-button label::before {
    margin-right: 24px;
  }

  .radio-button input[type=radio]:checked + label::after {
    content: "";
    height: 12px;
    width: 12px;
    left: 16px;
  }
}