.tasks-property-accordion .MuiAccordionSummary-root {
	padding: 32px 42px 36px 32px;
}

.tasks-property-accordion .MuiAccordionSummary-content {
	margin: 0px;
}

.tasks-property-accordion .title-container {
	border: 0px;
	display: flex;
	align-items: center;
}

.tasks-property-accordion .title-container svg {
	font-size: 24px;
	color: var(--light-gray);
	padding-right: 24px;
}

.tasks-property-accordion .title-container .title {
	border: 0px;
	padding: 0px;
	font-size: 24px;
	line-height: 36px;
	color: var(--dark-gray-text);
	font-weight: 400;
}

.tasks-property-accordion .arrow-icon {
	color: var(--light-gray-text);
	font-size: 24px;
}

.tasks-property-accordion .success svg {
	color: var(--primary-color)
}

.tasks-property-accordion .success.arrow-icon {
	display: none;
}

.tasks-property-accordion .content-container {
	padding: 0px 32px 36px;
	width: 100%;
	box-sizing: border-box;
}

.tasks-property-accordion .button-container {
	text-align: right;
	padding: 32px 32px 24px 32px;
}

.tasks-property-accordion .button.MuiButtonBase-root {
	width: 207px;
}

.tasks-property-accordion  .MuiAccordionDetails-root {
	padding: 0px;
}

.tasks-property-accordion .MuiAccordionSummary-content.Mui-expanded {
	margin: 0px;
}

.tasks-property-accordion .MuiIconButton-root {
	padding: 0px; 
}

.tasks-property-accordion .MuiAccordionSummary-root {
	min-height: 0;
}

@media only screen and (max-width: 1080px) {
	.tasks-property-accordion .MuiAccordionSummary-root {
		padding: 20px 30px 20px 24px;
	}

	.tasks-property-accordion .title-container svg {
		font-size: 16px;
		padding-right: 28px;
	}

	.tasks-property-accordion .title-container .title {
		font-size: 16px;
		line-height: 24px;
	}

	.tasks-property-accordion .content-container {
		padding: 4px 24px 40px;
	}

	.tasks-property-accordion .button-container {
		padding: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-property-accordion .MuiAccordionSummary-root {
		padding: 16px 21px 16px 16px;
	}

	.tasks-property-accordion .title-container svg {
		font-size: 12px;
		padding-right: 12px;
	}

	.tasks-property-accordion .title {
		font-size: 14px;
	}

	.tasks-property-accordion .content-container {
		padding: 0px 16px 32px;
	}

	.tasks-property-accordion .button-container {
		padding: 16px;
	}

	.tasks-property-accordion .button.MuiButtonBase-root {
		width: 100%;
		font-size: 14px;
	}
}
