.purchase-preapproval .date-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.purchase-preapproval .buttons {
  margin-bottom: 94px;
}

@media only screen and (max-width: 767px) {
  .purchase-preapproval .date-container {
    gap: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .purchase-preapproval .date-container {
    gap: 16px;
  }
}