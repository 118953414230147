.email-reminder.warning {
	color: #b88218;
	border-color: #f6d089;
	background-color: #fff6ed;
}

.email-reminder.warning .button.MuiButton-contained {
	background-color: #b88218;
}

.email-reminder.danger {
	color: #D65B5B;
	border-color: #FF5858;
	background-color: #FFECEC;
}

.email-reminder.danger .button.MuiButton-contained {
	background-color: #FF5858;
}
