.custom-accordion-summary .MuiAccordionSummary-root.Mui-expanded {
	min-height: 0;
}

.custom-accordion-summary .MuiAccordionSummary-root {
	padding: 0;
}

.custom-accordion-summary .MuiAccordionSummary-content {
	margin: 24px 0;
}

.custom-accordion-summary .MuiAccordionSummary-content.Mui-expanded {
	margin: 24px 0;
}

@media only screen and (max-width: 767px) {
  .custom-accordion-summary .MuiAccordionSummary-content {
		margin: 16px 0;
	}

	.custom-accordion-summary .MuiAccordionSummary-content.Mui-expanded {
		margin: 16px 0;
	}
}

@media only screen and (max-width: 600px) {
  .custom-accordion-summary .MuiAccordionSummary-content {
		margin: 12px 0;
	}

	.custom-accordion-summary .MuiAccordionSummary-content.Mui-expanded {
		margin: 12px 0;
	}
}