.simple-parking-type-item {
  display: flex;
  position: relative;
}

.simple-parking-type-item input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.simple-parking-type-item .checkmark {
  border: 2px solid #2FA67F;
  box-sizing: border-box;
  border-radius: 4px;
  width: 25.7px;
  height: 24px;
  cursor: pointer;
}

.simple-parking-type-item input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.simple-parking-type-item .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.simple-parking-type-item input:checked ~ .checkmark:after {
  display: block;
}

.simple-parking-type-item .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.simple-parking-type-item .details-container {
  margin-left: 24px;
  display: flex;
  width: 100%;
}

.simple-parking-type-item .image {
  width: 94px;
  height: 94px;
}

.simple-parking-type-item .details {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.simple-parking-type-item .parking-type-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
}

.simple-parking-type-item .parking-type-sub-title {
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-gray-text);
  margin-bottom: 16px;
}

.simple-parking-type-item .buttons-container {
  display: flex;
  align-items: center;
}

.simple-parking-type-item .button {
  height: auto;
  width: auto;
  min-width: 0px;
  padding: 3px;
  border-radius: 4px;
  margin: 0px;
}

.simple-parking-type-item .button.Mui-disabled {
  color: var(--light-gray);
  border: 1px solid var(--light-gray);
}

.simple-parking-type-item .MuiButton-label {
  width: 16px;
  height: 16px;
}

.simple-parking-type-item .button-icon {
  width: 16px;
  height: 16px;
  fill: var(--light-gray);
  transition: fill ease-in-out 0.1s;
}

.simple-parking-type-item .button-icon.checked {
  fill: var(--primary-color);
}

.simple-parking-type-item .capacity {
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px;
  color: var(--black);
}

.simple-parking-type-item .capacity.disabled {
  color: var(--light-gray);
}

@media only screen and (max-width: 767px) {
  .simple-parking-type-item .image {
    width: 104px;
    height: 104px;
  }

  .simple-parking-type-item .details {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .simple-parking-type-item .checkmark {
    width: 21.6px;
    height: 20px;
  }

  .simple-parking-type-item .checkmark:after {
    left: 7.5px;
    top: 4px;
    width: 3px;
    height: 8px;
    border-width: 0 2px 2px 0;
  }

  .simple-parking-type-item .details-container {
    margin-left: 12px;
  }

  .simple-parking-type-item .image {
    width: 77px;
    height: 77px;
  }

  .simple-parking-type-item .details {
    margin-left: 16px;
  }

  .simple-parking-type-item .parking-type-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .simple-parking-type-item .parking-type-sub-title {
    font-size: 12px;
    line-height: 18px;
  }

  .simple-parking-type-item .MuiButton-label {
    width: 12px;
    height: 12px;
  }
  
  .simple-parking-type-item .button-icon {
    width: 12px;
    height: 12px;
  }
  
  .simple-parking-type-item .capacity {
    font-size: 12px;
    line-height: 18px;
    margin: 0 12px;
  }
  
}