.tasks-additional-employment .radio-container {
  margin-bottom: 32px;
}

.tasks-additional-employment .radio-button:not(:last-child) {
  margin-bottom: 16px;
}

.tasks-additional-employment .input-container {
  margin-bottom: 30px;
}

.tasks-additional-employment .text-field:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) { 
  .tasks-additional-employment .radio-container {
    margin-bottom: 24px;
  }

  .tasks-additional-employment .input-container {
    margin-bottom: 0px;
  }

  .tasks-additional-employment .text-field:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) { 
  .tasks-additional-employment .radio-container {
    margin-bottom: 16px;
  }
  
  .tasks-additional-employment .radio-button:not(:last-child) {
    margin-bottom: 4px;
  }
  
  .tasks-additional-employment .input-container {
    margin-bottom: 0px;
  }
  
  .tasks-additional-employment .text-field:not(:last-child) {
    margin-bottom: 24px;
  }
}
