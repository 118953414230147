.overview-progress {
	display: flex;
	justify-content: space-between;
	height: 132px;
	margin-bottom: 56px;
}

.overview-progress .progress {
	position: relative;
}

.overview-progress .circle {
	background-color: var(--light-green);
	width: 48px;
	height: 48px;
	border-radius: 25px;
}

.overview-progress .sign,
.overview-progress .check {
	text-align: center;
	line-height: 48px;
	font-size: 24px;
	font-weight: 700;
	color: var(--primary-color);
}

.overview-progress .check {
	position: relative;
	top: 2px;
}

.overview-progress .grow {
	flex-basis: 33%;
}

.overview-progress .bar {
	height: 12px;
	width: 100%;
	background-color: var(--light-green);
	position: absolute;
	top: 18px;
	left: 24px;
	z-index: -1;
}

.overview-progress .check {
	color: white;
}

.overview-progress .progress.active .circle,
.overview-progress .progress.active .bar {
	background-color: var(--primary-color);
}

.overview-progress .content {
	margin-top: 24px;
	color: var(--primary-color);
	font-size: 20px;
	line-height: 30px;
	max-width: 114px;
	position: absolute;
}

.overview-progress .verified .content {
	left: -24px;
}

.overview-progress .approved .content {
	right: 0px;
	text-align: right;
}

@media only screen and (max-width: 1080px) {
	.overview-progress {
		margin-bottom: 64px;
		height: 94px;
	}

	.overview-progress .circle {
		width: 32px;
		height: 32px;
	}

	.overview-progress .sign,
	.overview-progress .check {
		line-height: 32px;
		font-size: 16px;
	}

	.overview-progress .bar {
		height: 6px;
		top: 14px;
		left: 16px;
	}

	.overview-progress .content {
		margin-top: 16px;
		color: var(--primary-color);
		font-size: 16px;
		line-height: 24px;
		max-width: 90px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-progress {
		height: 66px;
		margin-bottom: 32px;
	}

	.overview-progress .circle {
		width: 24px;
		height: 24px;
	}

	.overview-progress .sign,
	.overview-progress .check {
		line-height: 24px;
		font-size: 12px;
	}

	.overview-progress .bar {
		height: 4px;
		top: 10px;
		left: 12px;
	}

	.overview-progress .content {
		margin-top: 11px;
		font-size: 12px;
		line-height: 15px;
		max-width: 68px;
	}

	.overview-progress .verified .content {
		left: -18px;
	}
}
