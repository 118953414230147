.text-field,
.form-input,
.form-input-label {
  box-sizing: border-box;
}

.text-field {
  position: relative;
}

.text-field .text-field-container {
  position: relative;
}

.text-field .form-input, 
.text-field .form-input-label {
  font-size: 20px;
  line-height: 30px;
}

.text-field .form-input {
  background: none;
  background-color: white;
  color: var(--black);
  display: block;
  border: none;
  border: 1px solid #767676;
  border-radius: 8px;
  width: 100%;
  padding: 25px 16px 11px 16px;
}

.text-field .form-input:hover, 
.text-field .form-input.focus {
  border: 1px solid var(--primary-color);
}

.text-field .form-input.disabled {
  border: 1px solid var(--light-gray-text);
}

.text-field .form-input.error {
  border: 1px solid var(--danger);
}

.text-field .form-input[type="password"]{
  letter-spacing: 2px;
}

.text-field .form-input-label {
  color: #767676;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  transform: translateY(19px);
  top: 0px;
  left: 16px;
  transition: 0.1s ease-out all;
}

.text-field:hover .form-input-label, 
.text-field .form-input-label.focus {
  color: var(--primary-color)
}

.text-field .form-input-label.disabled {
  color: var(--light-gray-text)
}

.text-field .form-input-label.shrink,
.text-field .message-label {
  font-size: 12px;
  line-height: 18px;
}

.text-field .form-input-label.shrink {
  transform: translateY(8px);
}

.text-field .form-input-label.error,
.text-field .message-label.error {
  color: var(--danger); 
}

.text-field .message-label {
  margin: 0 16px 0 16px;
  position: absolute;
  color: #767676
}

/* Remove arrow on type number */

.text-field input::-webkit-outer-spin-button,
.text-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-field input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 767px) {
  .text-field .form-input-label {
    font-size: 16px;
    line-height: 24px;
    transform: translateY(18px);
  }

  .text-field .form-input {
    padding: 27px 16px 11px 16px;
    font-size: 16px;
    line-height: 18px;
  }

  .text-field .form-input-label.shrink {
    transform: translateY(6px);
  }
}

@media only screen and (max-width: 600px) {
  .text-field .form-input,
  .text-field .form-input-label {
    font-size: 14px;
    line-height: 21px;
  }

  .text-field .form-input {
    padding: 18px 12px 7px 12px;
    border-radius: 4px;
  }

  .text-field .form-input-label {
    left: 12px;
    transform: translateY(14px);
  }

  .text-field .form-input-label.shrink,
  .text-field .message-label {
    font-size: 10px;
    line-height: 15px;
  }

  .text-field .form-input-label.shrink {
    transform: translateY(4px);
  }

  .text-field .message-label {
    margin: 0 12px 0 12px;
  }
}

