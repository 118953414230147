.preapproval-bar {
	background: white;
	z-index: 999;
	width: calc(1280px - 64px);
	max-width: 1280px;
}

.preapproval-bar .mobile-menu {
	display: block;
}

.preapproval-bar .title {
	font-size: 24px;
	margin-bottom: 0px;
}

.preapproval-bar + .offset {
	border-bottom: solid 3px var(--primary-color);
	height: 0px;
}

.preapproval-bar .preapproval-bar-container {
	display: flex;
	justify-content: space-between;
	min-height: 72px;
	font-size: 20px;
	font-weight: bold;
	padding-right: 32px;
}

.preapproval-bar .preapproval-bar-left,
.preapproval-bar .preapproval-bar-right {
	display: flex;
	align-items: center;
}

.preapproval-bar .menu,
.preapproval-bar .menu-nav,
.preapproval-bar .dropdown {
	display: flex;
	align-items: center;
}

.preapproval-bar .menu-nav {
	margin-right: 56px;
}

.preapproval-bar .menu,
.preapproval-bar .auth {
	margin-top: 3px;
}

.preapproval-bar .auth {
	color: var(--primary-color);
	cursor: pointer;
}

.preapproval-bar .logo {
	width: 182px;
	cursor: pointer;
}

@media only screen and (max-width: 1279px) {
	.preapproval-bar {
		width: calc(100% - 64px);
	}
	.preapproval-bar .preapproval-bar-container {
		min-height: 64px;
	}

	.preapproval-bar .menu,
	.preapproval-bar .auth {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.preapproval-bar {
		padding: 0px 16px;
		width: calc(100% - 32px);
	}

	.preapproval-bar .preapproval-bar-container {
		min-height: 48px;
	}

	.preapproval-bar .logo {
		width: auto;
	}

	.preapproval-bar .logo,
	.preapproval-bar .mobile-menu-icon {
		height: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.preapproval-bar .title {
		font-size: 16px;
		margin-bottom: 0px;
	}
}