.coming-soon-main .coming-soon-main-container {
  max-width: 1072px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 65px;
}

.coming-soon-main .content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.coming-soon-main img {
  height: auto;
  width: 377px;
}

.coming-soon-main .input-container {
  width: 440px;
}

.coming-soon-main .title {
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 12px;
}

.coming-soon-main .sub-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 32px;
}

.coming-soon-main div:nth-child(3) {
  margin-bottom: 40px;
}

.coming-soon-main .button {
  width: 207px;
}

.coming-soon-main .MuiCircularProgress-colorPrimary {
  color: var(--primary-color);
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (max-width: 1080px) {

  .coming-soon-main .content-container {
    display: flex;
    flex-direction: column;
    max-width: 472px;
    margin: auto;
  }

  .coming-soon-main img {
    width: 238px;
    margin: 0px auto 72px auto;
  }

  .coming-soon-main .input-container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  
  .coming-soon-main img {
    width: 238px;
    margin: 0px auto 80px auto;
  }

  .coming-soon-main .button {
    height: 60px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {

  .coming-soon-main .coming-soon-main-container {
    margin-top: 40px;
  }

  .coming-soon-main .content-container {
    display: flex;
    flex-direction: column;
    max-width: 288px;
  }

  .coming-soon-main img {
    width: 119px;
    margin: 0px auto 40px auto;
  }

  .coming-soon-main .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  .coming-soon-main .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .coming-soon-main .button {
    height: 60px;
    font-size: 20px;
  }

  .coming-soon-main div:nth-child(3) {
    margin-bottom: 32px;
  }

  .coming-soon-main .button {
    height: 48px;
    font-size: 14px;
    width: 136px;
  }

  .coming-soon-main .MuiCircularProgress-colorPrimary {
    color: var(--primary-color);
    width: 21px !important;
    height: 21px !important;
  }
}