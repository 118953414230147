.verification-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.verification-input .resend {
	margin-bottom: 64px;
	color: var(--primary-color);
	font-weight: 700;
	font-size: 24px;
	cursor: pointer;
}

.verification-input .countdown {
	margin-bottom: 64px;
	color: #a3a3a3;
	font-weight: 700;
	font-size: 24px;
}

.verification-input .input-container {
	position: relative;
	display: flex;
	justify-content: center;
}

.verification-input .input {
	margin: auto;
	width: 505px !important;
	margin-bottom: 140px;
}

.verification-input .input input {
	width: 64px !important;
	margin-right: 24px;
	border: none !important;
	border-bottom: 2px solid var(--primary-color) !important;
	border-radius: 0px !important;
	font-size: 32px;
	font-family: 'Roboto', 'Helvetica Neue', Arial;
	font-weight: 700;
	color: var(--black);
	caret-color: var(--primary-color);
}

.verification-input .input input:last-child {
	margin-right: 0px;
}

.verification-input .error-label {
	color: var(--danger);
	position: absolute;
	font-size: 24px;
	margin: auto;
	top: 76px;
}

.verification-input form {
	width: 100%;
	text-align: center;
}

.verification-input .submit-button {
	width: 536px;
	height: 68px;
	font-size: 20px;
	margin-bottom: 24px;
}

.verification-input .change-email {
	font-size: 24px;
	color: var(--black);
}

.verification-input .change-email span {
	color: var(--primary-color);
}

@media only screen and (max-width: 1080px) {
	.verification-input .resend,
	.verification-input .countdown {
		margin-bottom: 72px;
		font-size: 20px;
	}

	.verification-input .input {
		width: 408px !important;
		margin-bottom: 134px;
	}

	.verification-input .input input {
		width: 48px !important;
		margin-right: 24px;
	}

	.verification-input .error-label {
		font-size: 20px;
	}

	.verification-input .submit-button {
		width: 417px;
		height: 60px;
		margin-bottom: 20px;
	}

	.verification-input .change-email {
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.verification-input .resend,
	.verification-input .countdown {
		margin-bottom: 24px;
		font-size: 14px;
	}

	.verification-input .input {
		width: 272px !important;
		margin-bottom: 85px;
	}

	.verification-input .error-label {
		font-size: 14px;
		margin: auto;
		top: 68px;
	}

	.verification-input .input input {
		width: 32px !important;
		margin-right: 16px;
		font-size: 20px;
	}

	.verification-input .submit-button {
		width: 100%;
		height: 48px;
		max-width: 288px;
		font-size: 14px;
	}

	.verification-input .change-email {
		font-size: 12px;
	}
}
