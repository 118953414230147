.side-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.side-menu > ul > li a {
	display: block;
	padding: 16px 16px 16px 32px;
}

.side-menu > ul > li.active {
	background-color: #33b389;
}

.side-menu .sub-menu > ul > li a {
	padding-top: 16px;
	padding-bottom: 0px;
}

.side-menu .sub-menu > ul > li:last-child a {
	padding-bottom: 16px;
}

.side-menu ul li,
.side-menu ul li a {
	cursor: pointer;
	color: white;
	font-size: 16px;
	font-weight: 700;
}

.side-menu .mobile-menu-list {
	display: flex;
	justify-content: space-between;
	padding: 16px 16px 16px 32px;
	align-items: center;
}

.side-menu .menu-list-arrow {
	position: relative;
}

.side-menu .sub-menu {
	padding-left: 16px;
}

.side-menu .sub-menu ul li a {
	font-weight: 400;
}

.side-menu .sub-menu ul li:first-child a {
	padding-top: 0px;
}

.side-menu .sub-menu ul li:last-child {
	padding-bottom: 0px;
}

.side-menu .menu-list-arrow {
	-webkit-transition: 0.1s ease-in-out;
	-moz-transition: 0.1s ease-in-out;
	-o-transition: 0.1s ease-in-out;
	transition: 0.1s ease-in-out;
}

.side-menu .menu-list-arrow.open {
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.side-menu .auth:nth-child(2) {
	margin-top: 8px;
}

.side-menu .auth {
	padding: 16px 32px 16px;
	color: white;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	display: block
}

.side-menu .hr {
	margin: 0 auto;
	margin-top: 16px;
	width: 232px;
}

@media only screen and (max-width: 767px) {
	.side-menu > ul > li a,
	.side-menu .mobile-menu-list {
		padding: 12px 16px;
	}

	.side-menu .sub-menu > ul > li a {
		padding-top: 12px;
		padding-bottom: 0px;
	}
	
	.side-menu .sub-menu > ul > li:last-child a {
		padding-bottom: 12px;
	}

	.side-menu .auth:nth-child(2) {
		margin-top: 12px;
	}

	.side-menu .auth {
		padding: 12px 16px;
	}

	.side-menu .hr {
		margin-top: 12px;
		width: 208px;
	}
}
