.summary-panel-content .fields {
	display: grid;
	grid-template-columns: 5fr 5fr;
	padding: 32px;
	grid-gap: 24px;
	overflow-wrap: anywhere;
}

.summary-panel-content .field {
	overflow-wrap: anywhere;
}

.summary-panel-content .field-title {
	color: var(--dark-gray-text);
	font-size: 20px;
	margin-bottom: 4px;
}

.summary-panel-content .field-value {
	font-size: 20px;
	color: var(--black);
	font-weight: 700;
}

@media only screen and (max-width: 1080px) {
	.summary-panel-content .fields {
		padding: 20px;
		grid-gap: 18px;
	}

	.summary-panel-content .field-title {
		font-size: 14px;
	}

	.summary-panel-content .field-value {
		font-size: 16px;
	}
}

@media only screen and (max-width: 767px) {
	.summary-panel-content .field-value {
		font-size: 14px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel-content .fields {
		padding: 16px;
		grid-gap: 16px;
	}
}

@media only screen and (max-width: 374px) {
	.summary-panel-content .fields {
		grid-template-columns: 100%;
	}
}
