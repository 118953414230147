.alert {
	padding: 16px;
	border-radius: 5px;
	border: 1px solid;
	display: flex;
	margin-bottom: 48px;
}

.alert .email {
	font-weight: 900;
}

.alert .icon-container {
	padding-right: 18px;
}

.alert .icon-container svg {
	font-size: 20px;
	height: 30px;
}

.alert .text {
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 24px;
}

.alert .button.MuiButton-contained {
	font-size: 20px;
	padding: 12px 32px;
	float: right;
	height: 54px;
}

@media only screen and (max-width: 1080px) {
	.alert {
		margin-bottom: 32px;
	}

	.alert .text {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 16px;
	}

	.alert .icon-container {
		padding-right: 16px;
	}

	.alert .icon-container svg {
		font-size: 16px;
		height: 24px;
	}

	.alert .button.MuiButton-contained {
		font-size: 16px;
		height: 48px;
	}
}

@media only screen and (max-width: 600px) {
	.alert {
		padding: 12px;
		margin-bottom: 24px;
	}

	.alert .text {
		font-size: 12px;
		line-height: 18px;
	}

	.alert .icon-container {
		padding-right: 12px;
	}

	.alert .icon-container svg {
		font-size: 16px;
		height: 18px;
	}

	.alert .button.MuiButton-contained {
		font-size: 12px;
		height: 34px;
		padding: 8px 15px;
	}
}
