.select-field .select-field-container {
  position: relative;
}

.select-field .form-input-label {
  font-size: 20px;
  line-height: 30px;
  color: #767676;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  transform: translateY(19px);
  top: 0px;
  left: 16px;
  transition: 0.1s ease-out all;
}

.select-field .select-field-container:hover .form-input-label,
.select-field .form-input-label.focus {
  color: var(--primary-color)
}

.select-field .form-input-label.shrink,
.select-field .message-label {
  font-size: 12px;
  line-height: 18px;
}

.select-field .form-input-label.shrink {
  transform: translateY(8px);
}

.select-field.error .form-input-label,
.select-field.error .message-label{
  color: var(--danger) !important; 
}

.select-field .message-label {
  margin: 0 0 0 16px;
  position: absolute;
  color: #767676;
}


.select-field .react-select__control {
  border: 1px solid #767676;
  padding: 18px 16px;
  border-radius: 8px;
  cursor: pointer;
}


.select-field .react-select__control:hover {
  border-color: var(--primary-color);
}

.select-field .react-select__control--is-focused {
  border: 1px solid var(--primary-color);
  box-shadow: none;
}

.select-field .react-select__control--is-focused:hover {
  border-color: var(--primary-color);
}


.select-field .react-select__value-container {
  padding: 0;
  position: relative;
  top: 7px;
}

.select-field .react-select__value-container > div {
  margin: 0px;
  padding: 0px;
}

.select-field .react-select__single-value {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin: 0;
}

.select-field .react-select__placeholder {
  display: none;
}

.select-field .react-select__input {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin: 0;
  padding: 0;
}

.select-field .react-select__indicator {
  padding: 0;
  color: #767676;
}

.select-field .react-select__indicator-separator {
  display: none;
}

.select-field .react-select__menu-list {
  max-height: 150px;
} 

.select-field .react-select__option {
  padding: 12px 12px;
}

.select-field .react-select__option--is-focused {
  background-color: #E6F4F0;
}

.select-field .react-select__option--is-selected {
  background-color: #2FA67F;
}

@media only screen and (max-width: 767px) {
  .select-field .react-select__control {
    padding: 16px 16px;
  }
  
  .select-field .react-select__input,
  .select-field .react-select__single-value {
    font-size: 16px;
    line-height: 18px;
  }

  .select-field .form-input-label {
    font-size: 16px;
    line-height: 24px;
    transform: translateY(18px);
    left: 16px;
  }

  .select-field .form-input-label.shrink {
    transform: translateY(6px);
  }
}

@media only screen and (max-width: 600px) {
  .select-field .react-select__control {
    padding: 11px 12px 11px 12px;
    border-radius: 4px;
  }

  .select-field .react-select__input,
  .select-field .react-select__single-value {
    font-size: 14px;
    line-height: 21px;
  }

  .select-field .form-input-label {
    font-size: 14px;
    line-height: 21px;
    transform: translateY(14px);
    left: 12px;
  }

  .select-field .form-input-label.shrink,
  .select-field .message-label {
    font-size: 10px;
    line-height: 15px;
  }

  .select-field .form-input-label.shrink {
    transform: translateY(4px);
  }

  .select-field .message-label {
    margin: 0 0 0 12px;
  }
}

/* ERROR */

.select-field.error .form-input-label,
.select-field.error .message-label{
  color: var(--danger) !important; 
}

.select-field.error .react-select__control {
  border: 1px solid var(--danger) !important;
}

/* DISABLED */

.select-field.disabled .form-input-label,
.select-field.disabled .message-label{
  color: var(--light-gray-text) !important; 
}

.select-field.disabled .react-select__control {
  border: 1px solid var(--light-gray-text) !important;
  background-color: #ffffff;
}

