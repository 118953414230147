.mortgage-preapproval .date-container {
  margin-top: 80px;
}

.mortgage-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.mortgage-preapproval .buttons {
  margin-bottom: 94px;
}

@media only screen and (max-width: 767px) {
  .mortgage-preapproval .date-container {
    margin-top: 72px;
  }

  .mortgage-preapproval .col-2 {
    gap: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .mortgage-preapproval .date-container {
    margin-top: 56px;
  }

  .mortgage-preapproval .col-2 {
    gap: 16px;
  }
}