.preapproval {
	background-color: #f5fffc;
}

.preapproval .preapproval-container {
	max-width: 1280px;
	margin: auto;
	background-color: white;
}

.preapproval .content {
	max-width: 600px;
	margin: 48px auto 0px;
}

.preapproval .title {
	font-size: 40px;
	line-height: 58px;
	margin-bottom: 32px;
	width: 100%;
}

.preapproval .buttons {
	margin-top: 80px;
}

.preapproval .button {
	width: 207px;
	margin-right: 28px;
}

.preapproval .toast {
	position: absolute;
	top: 0px;
	left: auto;
	margin-left: -32px;
}

.preapproval .toast .toast-item {
	width: 1280px;
}

.preapproval .small-text span {
  color: var(--primary-color);
  font-weight: bold;
}

.preapproval .MuiCircularProgress-colorPrimary {
  color: #ffffff;
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (min-width: 1279px) {
	.preapproval, .preapproval .preapproval-container{
		height: 100% !important;
		min-height: 100vh;
	}
}

@media only screen and (max-width: 1279px) {
	.preapproval .toast {
		left: 0px;
		margin-left: 0;
	}

	.preapproval .toast .toast-item {
		width: 100vw;
	}
}

@media only screen and (max-width: 767px) {
	.preapproval .content {
		max-width: 472px;
		margin: 80px auto 0px;
	}

	.preapproval .title {
		font-size: 32px;
		line-height: 48px;
		margin-bottom: 24px;
	}

	.preapproval .buttons {
		margin-top: 72px;
	}
}

@media only screen and (max-width: 600px) {
	.preapproval .content {
		margin: 24px auto 0px;
	}

	.preapproval .title {
		font-size: 24px;
		line-height: 36px;
	}

	.preapproval .buttons {
		margin-top: 56px;
		display: flex;
		justify-content: space-between;
	}

	.preapproval .button {
		width: calc(50% - 8px);
		margin-right: 0px;
	}

	.preapproval .MuiCircularProgress-colorPrimary {
		width: 21px !important;
		height: 21px !important;
	}
}
