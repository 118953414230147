.sub-bar .sub-bar-container {
	padding: 14px 42px;
}

.sub-bar a {
	margin-right: 48px;
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
	color: var(--light-gray-text);
}

@media only screen and (max-width: 1279px) {
	.sub-bar .sub-bar-container {
		padding: 12px 0px;
	}
}

@media only screen and (max-width: 767px) {
	.sub-bar .sub-bar-container {
		padding: 8px 0px;
	}
	
	.sub-bar a {
		margin-right: 24px;
		font-size: 16px;
		line-height: 24px;
	}
}
