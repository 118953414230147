.footer {
	background-color: #fafffd;
}

.footer .footer-container {
	padding: 58px 32px 24px;
}

.footer .cols {
	display: flex;
	justify-content: space-between;
	line-height: 24px;
}

.footer .col {
	display: flex;
	flex-direction: column;
}

.footer img.logo {
	width: 159px;
	margin-bottom: 42px;
}

.footer .social-media {
	margin: 0px 0px 25px;
	font-size: 16px;
	line-height: 24px;
}

.footer .social-media img {
	margin-right: 13px;
}

.footer .address-one {
	font-weight: 700;
	margin-bottom: 8px;
}

.footer .address-two {
	margin-bottom: 4px;
}

.footer .copyright {
	margin-top: 71px;
	text-align: center;
	color: var(--dark-gray-text);
}

.footer .col:not(:first-child) > div,
.footer .col:not(:first-child) > a {
	font-size: 16px;
	margin-bottom: 24px;
	line-height: 24px;
}

.footer .mobile,
.footer .mobile-phone {
	display: none;
}

.footer .all {
	display: block !important;
}

.footer .link {
	cursor: pointer;
}

@media only screen and (max-width: 1279px) {
	.footer .desktop {
		display: none;
	}

	.footer .mobile {
		display: block;
	}

	.footer .col {
		width: 35%;
	}

	.footer .col:not(:first-child) > div,
	.footer .col:not(:first-child) > a {
		margin-bottom: 17px;
	}

	.footer .last {
		margin-bottom: 69px !important;
	}
	
}

@media only screen and (max-width: 767px) {
	.footer .mobile-phone {
		display: block;
	}

	.footer .non-mobile-phone {
		display: none;
	}

	.footer .col {
		width: 100%;
		text-align: center;
	}

	.footer .logo {
		margin: auto;
	}

	.footer .col:not(:first-child) > div,
	.footer .col:not(:first-child) > a {
		margin-bottom: 12px;
	}

	.footer .last {
		margin-bottom: 32px !important;
	}
}

@media only screen and (max-width: 480px) {
	.footer .col {
		text-align: initial;
	}

	.footer .logo {
		margin: 0px;
	}

}
