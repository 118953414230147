.dashboard {
	display: flex;
	height: 100%;
}

.dashboard .app-bar,
.dashboard .offset {
	display: none;
	width: 100%;
}

.dashboard .main-section {
	padding: 48px 104px 48px 347px;
}
@media only screen and (max-width: 1080px) {
	.dashboard .main-section {
		padding: 32px 104px 32px 275px;
	}
}

@media only screen and (max-width: 767px) {
	.dashboard {
		display: block;
	}
	.dashboard .app-bar,
	.dashboard .offset {
		display: block;
	}

	.dashboard .side-bar {
		display: none;
	}

	.dashboard .main-section {
		padding: 32px;
	}
}

@media only screen and (max-width: 600px) {
	.dashboard .main-section {
		padding: 16px;
	}
}
