.signup-preapproval .title {
  margin-bottom: 16px;
}

.signup-preapproval .sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: var(--dark-gray-text);
  margin-bottom: 48px;
}

.signup-preapproval .google-button {
  width: 100%;
  border: 1px solid #767676;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 24px;
  padding: 15px 0;
  height: auto;
}

.signup-preapproval .google-icon {
  margin-right: 16px;
  height: 24px;
  width: 24px;
}

.signup-preapproval .line-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.signup-preapproval .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--light-gray-text);
}

.signup-preapproval .line + div {
  text-align: center;
  color: var(--light-gray-text);
  font-size: 20px;
  line-height: 21px;
  background-color: #ffffff;
  z-index: 3;
  padding: 0 16px;
}

.signup-preapproval .input-container:not(:first-child) {
  margin-top: 36px;
}

.signup-preapproval .input-container:nth-of-type(2) {
  margin-bottom: 64px;
}

.signup-preapproval .signup-button {
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  padding: 15px;
  height: auto;
}

.signup-preapproval .buttons {
  margin-bottom: 32px;
  margin-top: 48px;
}

.signup-preapproval .small-text {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: var(--dark-gray-text);
  margin-bottom: 24px;
  cursor: pointer;
}

.signup-preapproval .small-text span {
  color: var(--primary-color);
  font-weight: bold;
}

.signup-preapproval .MuiCircularProgress-colorPrimary {
  color: #ffffff;
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (max-width: 767px) {
  .signup-preapproval .title {
    margin-bottom: 12px;
  }

  .signup-preapproval .sub-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 48px;
  }

  .signup-preapproval .google-button {
    font-size: 20px;
    line-height: 24px;
    padding: 18px 0;
    height: auto;
  }
  
  .signup-preapproval .google-icon {
    margin-right: 12px;
  }

  .signup-preapproval .line + div {
    font-size: 16px;
    line-height: 21px;
  }

  .signup-preapproval .input-container:not(:first-child) {
    margin-top: 32px;
  }

  .signup-preapproval .input-container:nth-of-type(2) {
    margin-bottom: 64px;
  }

  .signup-preapproval .buttons {
    margin-top: 48px;
    margin-bottom: 24px;
  }

  .signup-preapproval .signup-button {
    font-size: 20px;
    line-height: 30px;
    padding: 15px;
  }
  
  .signup-preapproval .small-text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .signup-preapproval .title {
    margin-bottom: 8px;
  }

  .signup-preapproval .sub-title {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .signup-preapproval .google-button {
    font-size: 14px;
    line-height: 21px;
    padding: 13px 0 13px 0;
    margin-bottom: 8px;
    height: auto;
    border-radius: 4px;
  }

  .signup-preapproval .google-icon {
    margin-right: 8px;
    height: 18px;
    width: 18px;
  }

  .signup-preapproval .line-container {
    margin-bottom: 8px;
  }

  .signup-preapproval .line + div {
    font-size: 14px;
    line-height: 21px;
    padding: 0 14px;
  }

  .signup-preapproval .input-container:not(:first-child) {
    margin-top: 24px;
  }

  .signup-preapproval .input-container:nth-of-type(2) {
    margin-bottom: 69px;
  }

  .signup-preapproval .checkbox-container {
    margin-left: 6px;
  }

  .signup-preapproval .buttons {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .signup-preapproval .signup-button {
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    padding: 15px;
    height: auto;
    padding: 14px 0 13px 0;
  }
  
  .signup-preapproval .small-text {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }

  .signup-preapproval .MuiCircularProgress-colorPrimary {
    width: 21px !important;
    height: 21px !important;
  }
}