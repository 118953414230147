.overview-property {
	border-radius: 8px;
	border: 1px solid var(--light-gray);
	padding: 24px 32px;
}

.overview-property .title {
	font-size: 24px;
	line-height: 36px;
	font-weight: 700;
	color: var(--black);
	margin-bottom: 16px;
}

.overview-property .images-container {
	border-radius: 8px;
	overflow: hidden;
	margin-bottom: 24px;
	mask-image: radial-gradient(white, black);
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}

.overview-property .carousel img {
	height: 311px;
	object-fit: cover;
}

.overview-property .carousel.carousel-slider .control-arrow {
	background: rgba(0, 0, 0, 0.3);
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
	opacity: 1;
}

.overview-property .upload-container {
	height: 311px;
	background-color: var(--light-green);
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
}

.overview-property .content {
	margin: auto;
	text-align: center;
}

.overview-property .upload-description {
	font-size: 16px;
	margin-bottom: 8px;
}

.overview-property .upload-link {
	font-size: 16px;
	color: var(--primary-color);
	cursor: pointer;
}

.overview-property .property-details-grid {
	display: grid;
	grid-template-columns: 50% 50%;
}

.overview-property .detail.address {
	grid-column-start: 1;
	grid-column-end: 3;
}

.overview-property .detail:not(:last-child) {
	margin-bottom: 32px;
}

.overview-property .detail-title,
.overview-property .detail-value {
	font-size: 20px;
	line-height: 30px;
}

.overview-property .detail-title {
	color: var(--dark-gray-text);
	margin-bottom: 4px;
}

.overview-property .detail-value {
	color: var(--black);
	font-weight: 700;
}

@media only screen and (max-width: 1080px) {
	.overview-property {
		padding: 16px 20px;
	}

	.overview-property .title {
		margin-bottom: 24px;
	}

	.overview-property .carousel img {
		height: 210px;
	}

	.overview-property .upload-container {
		height: 210px;
	}

	.overview-property .detail-title,
	.overview-property .detail-value {
		font-size: 14px;
		line-height: 21px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-property {
		padding: 16px 16px;
	}

	.overview-property .title {
		font-size: 20px;
		line-height: 26px;
	}

	.overview-property .carousel img {
		height: 180px;
	}

	.overview-property .upload-container {
		height: 180px;
	}

	.overview-property .upload-description {
		font-size: 12px;
		line-height: 18px;
	}

	.overview-property .upload-link {
		font-size: 12px;
		line-height: 18px;
	}
}
