.property-details-preapproval .field-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 24px;
}

.property-details-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.property-details-preapproval .fields-container {
  margin: 48px 0;
}

.property-details-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .property-details-preapproval .field-title {
    font-size: 20px;
    line-height: 30px;
  }

  .property-details-preapproval .field-title:first-of-type {
    margin-top: 40px;
  }

  .property-details-preapproval .fields-container {
    margin: 40px 0;
  }

  .property-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .property-details-preapproval .field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .property-details-preapproval .col-2 {
    gap: 16px;
  }

  .property-details-preapproval .field-title:first-of-type {
    margin-top: 0px;
  }

  .property-details-preapproval .fields-container {
    margin: 24px 0 32px 0;
  }

  .property-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }

  .property-details-preapproval .custom-radio-container {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}



