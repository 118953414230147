.employment-details-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.employment-details-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .employment-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .employment-details-preapproval .col-2 {
    grid-template-columns: 1fr;
  }

  .employment-details-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }
}



