.current-living-preapproval .field-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 32px;
}

.current-living-preapproval .input-container:not(:last-child) {
  margin-bottom: 40px;
}

.current-living-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.current-living-preapproval .custom-radio-container {
  margin-bottom: 32px;
}

.current-living-preapproval .status-field-container {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .current-living-preapproval .field-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 24px;
  }

  .current-living-preapproval .input-container:not(:last-child) {
    margin-bottom: 40px;
  }

  .current-living-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .current-living-preapproval .custom-radio-container {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .current-living-preapproval .field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .current-living-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }

  .current-living-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .current-living-preapproval .custom-radio-container {
    margin-bottom: 16px;
  }

  .current-living-preapproval .status-field-container {
    margin-top: 24px;
  }
}



