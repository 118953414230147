.search-bar { 
  position: relative; 
  padding: 0; 
  margin: 0; 
}

.search-bar input[type="text"] { 
  margin:0; 
  padding: 15px 44px 15px 44px;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  border: 1px solid #767676;
  border-radius: 8px;
  width: 100%;
}

.search-bar .search-icon { 
  position: absolute; 
  top: 50%;
  transform: translateY(-50%); 
  left: 16px; 
  width: 20px; 
  height: 20px; 
}

.search-bar .close {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #767676;
}

@media only screen and (max-width: 600px) {
  .search-bar input[type="text"] { 
    padding: 11px 40px;
    font-size: 14px;
    line-height: 21px;
  }

  .search-bar .search-icon { 
    position: absolute; 
    top: 50%;
    transform: translateY(-50%); 
    left: 16px; 
    width: 16px; 
    height: 16px; 
  }
}
