.search-preapproval .dropdown-container {
  margin-top: 16px;
  background-color: white;
  z-index: 3;
  width: 100%;
  -webkit-transition: visibility 200ms ease-out;
  transition: visibility 200ms ease-out;
  box-sizing: border-box;
}

.search-preapproval .suggestion-item {
  padding: 16px 20px;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 24px;
  color: var(--black);
  cursor: pointer;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
} 

.search-preapproval .suggestion-item:first-child {
  border-top: 1px solid #e8e8e8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.search-preapproval .suggestion-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.search-preapproval .suggestion-item {
  border-bottom: 1px solid #e8e8e8;
}

.search-preapproval .text-gray {
  color: #767676;
}

.search-preapproval .text-black {
  color: var(--black);
}
.search-preapproval .suggestion-item.not-found {
  padding: 16px;
  display: flex;
  align-items: flex-start;
}

.search-preapproval .gray-marker {
  height: 24px;
  width: 24px;
  margin-top: 3px;
}

.search-preapproval .item-text-container {
  margin-left: 12px;
}

.search-preapproval .text-top {
  font-size: 20px;
  line-height: 30px;
  color: #767676;
}

.search-preapproval .text-bottom {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-color);
}

@media only screen and (max-width: 767px) {
  .search-preapproval .suggestion-item {
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  
  .search-preapproval .gray-marker {
    height: 16px;
    width: 16px;
    margin-top: 4px;
  }

  .search-preapproval .item-text-container {
    margin-left: 16px;
  }

  .search-preapproval .text-top {
    font-size: 16px;
    line-height: 24px;
  }
  
  .search-preapproval .text-bottom {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .search-preapproval .suggestion-item {
    padding: 12px;
    font-size: 12px;
    line-height: 21px;
  } 

  .search-preapproval .suggestion-item.not-found {
    padding: 12px;
  }

  .search-preapproval .gray-marker {
    margin-top: 3px;
  }

  .search-preapproval .item-text-container {
    margin-left: 4px;
  }

  .search-preapproval .text-top {
    font-size: 12px;
    line-height: 21px;
  }
  
  .search-preapproval .text-bottom {
    font-size: 12px;
    line-height: 21px;
  }

  .search-preapproval .title {
    transition: 0.4s ease-out transform;
  }
}