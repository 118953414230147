.simple-list-group > div {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 28px;
}

.simple-list-group > div:not(:first-child) {
  margin-top: 28px;
}

@media only screen and (max-width: 767px) {
  .simple-list-group > div {
    padding-bottom: 24px;
  }

  .simple-list-group > div:not(:first-child) {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .simple-list-group > div {
    padding-bottom: 16px;
  }

  .simple-list-group > div:not(:first-child) {
    margin-top: 16px;
  }
}