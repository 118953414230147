.overview-help {
	border-radius: 8px;
	border: 1px solid var(--light-gray);
	padding: 24px 32px;
	margin-bottom: 40px;
}

.overview-help .title {
	font-size: 24px;
	line-height: 36px;
	font-weight: 700;
	color: var(--black);
	margin-bottom: 16px;
}

.overview-help .description {
	font-size: 20px;
	line-height: 30px;
	color: var(--dark-gray-text);
	margin-bottom: 48px;
}

.overview-help .button.MuiButton-outlined {
	font-size: 16px;
	padding: 12px 26px;
	height: 48px;
}

@media only screen and (max-width: 1080px) {
	.overview-help {
		padding: 16px 20px;
		margin-bottom: 24px;
	}

	.overview-help .description {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-help {
		padding: 16px 16px;
		margin-bottom: 16px;
	}

	.overview-help .title {
		font-size: 20px;
		line-height: 26px;
	}

	.overview-help .description {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 32px;
	}

	.overview-help .button.MuiButton-outlined {
		font-size: 16px;
		padding: 10px 24px;
		height: 43px;
	}
}

@media only screen and (max-width: 480px) {
	.overview-help .button.MuiButton-outlined {
		width: 100%;
	}
}
