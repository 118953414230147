:root {
	--primary-color: #2fa67f;
	--secondary-color: #e97047;
	--light-blue: #f7fffc;
	--light-green: #eaf6f2;
	--black: #1a1a1a;
	--dark-green-text: #0e3226;
	--dark-gray-text: #484848;
	--light-gray: #d1d1d1;
	--light-gray-text: #a3a3a3;
	--danger: #ff5858;
}
