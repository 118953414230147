.looking-timeline-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .looking-timeline-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .looking-timeline-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }
}