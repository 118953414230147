.simple-property-item .tag {
  font-size: 12px;
  line-height: 15px;
  color: #2FA67F;
  background-color: #F0FFFA;
  padding: 8px;
  display: inline-block;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.simple-property-item .address {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: var(--black);
}

@media only screen and (max-width: 767px) {
  .simple-property-item .tag {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 4px
  }

  .simple-property-item .address {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}

@media only screen and (max-width: 600px) {
  .simple-property-item .tag {
    font-size: 10px;
    line-height: 15px;
  }

  .simple-property-item .address {
    font-size: 14px;
    line-height: 21px;
  }

}
