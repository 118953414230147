.buyback-preapproval .title {
  margin-bottom: 16px
}

.buyback-preapproval .sub-title {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 32px;
  color: var(--dark-gray-text);
}

@media only screen and (max-width: 767px) {
  .buyback-preapproval .title {
    margin-bottom: 12px
  }

  .buyback-preapproval .sub-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .buyback-preapproval .title {
    margin-bottom: 8px
  }

  .buyback-preapproval .sub-title {
    font-size: 14px;
    line-height: 21px;
  }
}