.financial-preapproval .title {
  margin-bottom: 16px;
}

.financial-preapproval .sub-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-gray-text);
  margin-bottom: 40px;
}

.financial-preapproval .price-field,
.financial-preapproval .textarea-custom {
  margin-top: 20px;
  margin-left: 48px;
}

.financial-preapproval .checkbox-label {
  font-weight: bold;
}

.financial-preapproval .textarea-custom + .price-field {
  margin-top: 36px;
}

.financial-preapproval .total-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-top: 28px;
  margin-left: 48px;
  color: var(--black);
}

.financial-preapproval .buttons {
  margin-bottom: 48px;
  margin-top: 80px;
}

@media only screen and (max-width: 767px) {
  .financial-preapproval .title {
    margin-bottom: 12px;
  }

  .financial-preapproval .sub-title {
    font-size: 16px;
    line-height: 24px;
  }

  .financial-preapproval .textarea-custom + .price-field {
    margin-top: 32px;
  } 

  .financial-preapproval .total-label {
    margin-top: 24px;
  }

  .financial-preapproval .buttons {
    margin-top: 72px;
  }
}

@media only screen and (max-width: 600px) {
  .financial-preapproval .title {
    margin-bottom: 16px;
  }

  .financial-preapproval .sub-title {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 30px;
  }

  .financial-preapproval .price-field,
  .financial-preapproval .textarea-custom {
    margin-top: 12px;
    margin-left: 32px;
  }

  .financial-preapproval .textarea-custom + .price-field {
    margin-top: 24px;
  }

  .financial-preapproval .total-label {
    margin-top: 16px;
    margin-left: 32px;
    font-size: 20px;
    line-height: 36px;
  }

  .financial-preapproval .buttons {
    margin-top: 56px;
  }
}