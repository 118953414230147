.verification .verification-container {
	padding: 120px 16px 0px 16px;
}

.verification .modal-overlay {
	background: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(32px);
}

@media only screen and (max-width: 1080px) {
	.verification: {
		padding: 80px 175px 0px 175px;
	}

	.verification .resend,
	.verification .countdown {
		margin-bottom: 72px;
		font-size: 20px;
	}

	.verification .verification-input .input {
		width: 408px !important;
		margin-bottom: 134px;
	}

	.verification .input input {
		width: 48px !important;
		margin-right: 24px;
	}

	.verification .submit-button {
		width: 417px;
		height: 60px;
		margin-bottom: 20px;
	}

	.verification .change-email {
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.verification: {
		padding: 40px 16px 0px 16px;
	}

	.verification .resend,
	.verification .countdown {
		margin-bottom: 78px;
		font-size: 14px;
	}

	.verification .verification-input .input {
		width: 272px !important;
		margin-bottom: 85px;
	}

	.verification .input input {
		width: 32px !important;
		margin-right: 16px;
		font-size: 20px;
	}

	.verification .submit-button {
		width: 100%;
		height: 48px;
		max-width: 288px;
		font-size: 14px;
	}

	.verification .change-email {
		font-size: 12px;
	}
}
