.custom-accordion .MuiAccordion-root {
	box-shadow: none;
	border-bottom: 1px solid #e8e8e8;
}

.custom-accordion .MuiAccordion-rounded:last-child { 
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.custom-accordion .MuiAccordion-rounded:first-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;

}

.custom-accordion .MuiAccordion-root::before {
	height: 0px;
}

.custom-accordion .MuiAccordion-root.Mui-expanded {
	margin: 0px;
}