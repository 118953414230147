.overview .overview-container {
	width: 620px;
}

@media only screen and (max-width: 1080px) {
	.overview .overview-container {
		width: 460px;
	}
}

@media only screen and (max-width: 767px) {
	.overview .overview-container {
		width: 100%;
	}
}
