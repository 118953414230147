.preapproval-success .preapproval-success-container {
	max-width: 1600px;
	position: relative;
	margin: auto;
}

.preapproval-success .content {
	padding: 75px 0px 0px 128px;
	max-width: 735px;
}

.preapproval-success .logo {
	width: 249px;
	margin-bottom: 32px;
}

.preapproval-success .title {
	font-size: 40px;
	line-height: 58px;
	font-weight: 700;
	color: var(--black);
	margin-bottom: 24px;
}

.preapproval-success .title span {
	color: var(--primary-color);
}

.preapproval-success .description {
	font-size: 24px;
	color: var(--dark-gray-text);
	margin-bottom: 72px;
}

.preapproval-success button {
	padding-left: 24px;
	padding-right: 24px;
}

.preapproval-success .hill-houses {
	position: absolute;
	bottom: 0px;
	left: calc(1600px - 833px);
}

@media only screen and (max-width: 1024px) {
	.preapproval-success .preapproval-success-container {
		max-width: 1600px;
		width: 100%;
		position: initial;
	}

	.preapproval-success .content {
		padding: 30px 50px 0px 50px;
		max-width: 650px;
		margin: auto;
	}

	.preapproval-success .logo {
		width: 175px;
	}

	.preapproval-success .description {
		margin-bottom: 56px;
	}

	.preapproval-success .hill-houses {
		width: 100vw;
		left: 0px;
		max-height: 20vh;
	}
}

@media only screen and (max-width: 768px) {
	.preapproval-success .content {
		padding: 21px 15px 0px 15px;
	}

	.preapproval-success .logo {
		width: 175px;
	}

	.preapproval-success .title {
		font-size: 32px;
		line-height: 150%;
	}

	.preapproval-success .description {
		font-size: 20px;
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 480px) {
	.preapproval-success .logo {
		width: 175px;
	}

	.preapproval-success .description {
		font-size: 14px;
	}

	.preapproval-success .title {
		font-size: 20px;
		margin-bottom: 16px;
	}
}
