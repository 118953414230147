.summary-property .title {
	font-size: 12px;
	display: inline-block;
	background-color: #f0fffa;
	text-transform: uppercase;
	color: var(--primary-color);
	font-weight: 400;
	padding: 4px 8px;
	border-radius: 4px;
	margin-bottom: 0;
}

.summary-property .description {
	color: var(--black);
	font-size: 24px;
	font-weight: 700;
}

.summary-property .field:first-child {
	grid-column-start: 1;
	grid-column-end: 3;
}

@media only screen and (max-width: 1080px) {
	.summary-property .description {
		font-size: 20px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-property .title {
		font-size: 10px;
	}

	.summary-property .description {
		font-size: 16px;
	}
}

@media only screen and (max-width: 374px) {
	.summary-property .fields {
		grid-template-columns: 1fr 1fr;
	}

	.summary-property .field:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}
