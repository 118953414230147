.summary-panel-header {
	padding: 24px 32px;
	border-bottom: 1px solid var(--light-gray);
	display: flex;
}

.summary-panel-header .icon-container {
	padding-right: 24px;
}

.summary-panel-header .title {
	color: var(--black);
	font-weight: 700;
	font-size: 24px;
	line-height: 150%;
	margin-bottom: 4px;
}

.summary-panel-header .description {
	color: var(--dark-gray-text);
	font-size: 20px;
}

@media only screen and (max-width: 1080px) {
	.summary-panel-header {
		padding: 20px;
	}

	.summary-panel-header .icon-container {
		padding-right: 16px;
	}

	.summary-panel-header .icon-container img {
		width: 60px;
	}

	.summary-panel-header .title {
		font-size: 20px;
	}

	.summary-panel-header .description {
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel-header {
		padding: 16px;
	}

	.summary-panel-header .icon-container {
		padding-right: 12px;
	}

	.summary-panel-header .icon-container img {
		width: 48px;
	}

	.summary-panel-header .title {
		font-size: 16px;
	}

	.summary-panel-header .description {
		font-size: 12px;
	}
}
