.file-item{
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 16px 23px 16px 23px;
  display: flex;
  box-sizing: border-box;
}

.file-item .gray-document-container {
  display: flex;
  align-items: center;
}

.file-item .gray-document {
  height: 42px;
  width: 33px;
}

.file-item .file-label-container{
  margin-left: 15.5px;
}

.file-item .file-name {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-item .file-size {
  font-weight: normal;
  font-size: 16px; 
  line-height: 24px;
  color: #767676;
}

.file-item .file-button-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.file-item .delete-button {
  cursor: pointer;
  width: 18.67px;
  height: 24px;
}

@media only screen and (max-width: 767px) {
  .file-item .gray-document {
    height: 35px;
    width: 27.5px;
  }

  .file-item .file-label-container{
    margin-left: 22.25px;
  }

  .file-item .file-name {
    font-size: 16px;
    line-height: 24px;
    width: 280px;
  }
  
  .file-item .file-size {
    font-size: 12px;
    line-height: 24px
  }
  
  .file-item .delete-button {
    width: 14px;
    height: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .file-item {
    padding: 13px 17px;
  }

  .file-item .gray-document {
    height: 28px;
    width: 22px;
  }

  .file-item .file-label-container{
    margin-left: 9px;
  }

  .file-item .file-name {
    font-size: 12px;
    line-height: 18px;
    width: 160px;
  }
  
  .file-item .file-size {
    font-size: 8px;
    line-height: 12px;
  }
  
  .file-item .delete-button {
    width: 11.67px;
    height: 15px;
  }
}