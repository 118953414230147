.side-bar {
	position: fixed;
}

.side-bar {
	background-color: var(--primary-color);
	width: 243px;
	height: 100%;
}

.side-bar .logo {
	margin: 25px 32px 32px;
	cursor: pointer;
}

.side-bar .profile-container {
	margin: 0px 22px 16px 32px;
	display: flex;
	align-items: center;
}

.side-bar .user-icon-container {
	margin-right: 12px;
	display: flex;
}

.side-bar .user-details-container {
	color: white;
	max-width: 189px;
}

.side-bar .full-name {
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 700;
}

.side-bar .email {
	font-size: 12px;
	overflow-wrap: anywhere;
}

.side-bar .switch-wrapper {
	position: absolute;
	bottom: 16px;
	right: 16px;
}

.side-bar .side-menu .hr {
	width: 100%;
}
