.summary-panel {
	border-radius: 8px;
	border: 1px solid var(--light-gray);
	margin-bottom: 32px;
}

@media only screen and (max-width: 1080px) {
	.summary-panel {
		margin-bottom: 24px;
	}
}

@media only screen and (max-width: 600px) {
	.summary-panel {
		margin-bottom: 16px;
	}
}
