.summary-targeted-property .field:first-child {
	grid-column-start: 1;
	grid-column-end: 3;
}

@media only screen and (max-width: 374px) {
	.summary-targeted-property .fields {
		grid-template-columns: 1fr 1fr;
	}

	.summary-targeted-property .field:first-child {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}
