.tasks-applicant-content {
	padding: 32px 32px 40px 32px;
	border-bottom: 1px solid var(--light-gray);
}

.tasks-applicant-content .content-title {
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 24px;
}

.tasks-applicant-content .content-notes {
	padding-left: 20px;
	margin-bottom: 36px;
}

.tasks-applicant-content .note {
	margin-bottom: 12px;
	font-size: 20px;
}

.tasks-applicant-content .upload-examples {
	display: flex;
	margin-bottom: 16px;
}

.tasks-applicant-content .upload-examples > div {
	width: 240px;
	height: 148px;
	border: 1px solid var(--light-gray);
	border-radius: 16px;
	z-index: 1;
	overflow: hidden;
	position: relative;
}

.tasks-applicant-content .good-example {
	margin-right: 36px;
}

.tasks-applicant-content .bad-example img {
	position: relative;
	top: 33px;
	left: 25px;
}

.tasks-applicant-content .icon-container {
	position: absolute;
	right: 8px;
	top: 2px;
	background-color: white;
	height: 32px;
	width: 32px;
	border-radius: 25px;
}

.tasks-applicant-content .icon-container svg {
	font-size: 32px;
}

.tasks-applicant-content .good-example svg {
	color: var(--primary-color);
}

.tasks-applicant-content .bad-example svg {
	color: var(--danger);
}

.tasks-applicant-content .disclaimer {
	font-size: 20px;
	margin-bottom: 40px;
}

@media only screen and (max-width: 1080px) {
	.tasks-applicant-content {
		padding: 20px 24px 32px;
	}

	.tasks-applicant-content .content-title {
		font-size: 16px;
		margin-bottom: 16px;
	}

	.tasks-applicant-content .content-notes {
		margin-bottom: 25px;
	}

	.tasks-applicant-content .note {
		font-size: 16px;
	}

	.tasks-applicant-content .upload-examples {
		margin-bottom: 12px;
	}

	.tasks-applicant-content .icon-container {
		height: 28px;
		width: 28px;
		z-index: 2;
	}

	.tasks-applicant-content .icon-container svg {
		font-size: 28px;
	}

	.tasks-applicant-content .upload-examples > div,
	.tasks-applicant-content img {
		width: 199px;
		height: 125px;
	}

	.tasks-applicant-content .good-example {
		margin-right: 25px;
	}

	.tasks-applicant-content .bad-example img {
		top: 25px;
	}

	.tasks-applicant-content .disclaimer {
		font-size: 12px;
		margin-bottom: 32px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-applicant-content {
		padding: 16px 16px 20px;
	}
	.tasks-applicant-content .content-title {
		font-size: 12px;
		margin-bottom: 16px;
	}
	.tasks-applicant-content .content-notes {
		margin-bottom: 16px;
	}
	.tasks-applicant-content .note {
		margin-bottom: 8px;
		font-size: 12px;
	}

	.tasks-applicant-content .upload-examples {
		margin-bottom: 4px;
	}

	.tasks-applicant-content .upload-examples > div {
		border-radius: 8px;
	}

	.tasks-applicant-content .icon-container {
		height: 17px;
		width: 17px;
	}

	.tasks-applicant-content .icon-container svg {
		font-size: 17px;
	}

	.tasks-applicant-content .upload-examples > div,
	.tasks-applicant-content img {
		width: 120px;
		height: 74px;
	}

	.tasks-applicant-content .good-example {
		margin-right: 16px;
	}

	.tasks-applicant-content .bad-example img {
		top: 15px;
	}

	.tasks-applicant-content .disclaimer {
		font-size: 8px;
		margin-bottom: 20px;
	}
}
