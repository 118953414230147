.address-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

.address-preapproval .input-container.col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.address-preapproval .city-district-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px
}

@media only screen and (max-width: 767px) {
  .address-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }

  .address-preapproval .city-district-container {
    grid-template-columns: 1fr;
    gap: 0px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .address-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }
  
  .address-preapproval .input-container.col-2 {
    gap: 16px;
  }
  
  .address-preapproval .city-district-container {
    margin-bottom: 24px;
  }
}


