.tasks .tasks-container {
	width: 620px;
}

@media only screen and (max-width: 1080px) {
	.tasks .tasks-container {
		width: 460px;
	}

	/* File Upload Dropzone */

	.tasks .file-upload-dropzone .base {
    height: 206px;
  }

  .tasks .file-upload-dropzone .label-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .tasks .file-upload-dropzone .label-subtitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }

  .tasks .file-upload-dropzone .image-container {
    margin-bottom: 19px
  }

  .tasks .file-upload-dropzone .drag-and-drop-image {
    width: 24px;
    height: 24px;
  }

	/* Multiple File Upload Dropzone */

	.tasks .multiple-file-upload-dropzone .base {
    height: 206px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled{
    height: 110px;
    margin-bottom: 24px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled.reject + .message-label {
    top: 110px;
  }

  .tasks .multiple-file-upload-dropzone .label-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .tasks .multiple-file-upload-dropzone .label-subtitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }

  .tasks .multiple-file-upload-dropzone .image-container {
    margin-bottom: 19px
  }

  .tasks .multiple-file-upload-dropzone .drag-and-drop-image {
    width: 24px;
    height: 24px;
  }

  .tasks .multiple-file-upload-dropzone .file-item:not(:last-of-type) {
    margin-bottom: 16px
  }

	/* File Item */

	.tasks .file-item .gray-document {
    height: 35px;
    width: 27.5px;
  }

  .tasks .file-item .file-label-container{
    margin-left: 22.25px;
  }

  .tasks .file-item .file-name {
    font-size: 16px;
    line-height: 24px;
    width: 280px;
  }
  
  .tasks .file-item .file-size {
    font-size: 12px;
    line-height: 24px
  }
  
  .tasks .file-item .delete-button {
    width: 14px;
    height: 18px;
  }

  /* Form Modal */

  .tasks .modal-form-container {
    width: 481px;
  }

  .tasks .modal-form-container .modal-exit-button {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
  }

  .tasks .modal-form-container .modal-title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .tasks .modal-form-container .modal-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  .tasks .modal-form-container .modal-submit-button {
    font-size: 20px;
    line-height: 30px;
    height: 60px;
  }

}

@media only screen and (max-width: 767px) {
	.tasks .tasks-container {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {

	/* File Upload Dropzone */

	.tasks .file-upload-dropzone .base {
    height: 121px;
  }

  .tasks .file-upload-dropzone .label-title {
    font-size: 14px;
    line-height: 21px;
  }
  
  .tasks .file-upload-dropzone .label-subtitle {
    font-size: 10px;
    line-height: 18px;
    margin-top: 2px;
  }

  .tasks .file-upload-dropzone .image-container {
    margin-bottom: 8px
  }

  .tasks .file-upload-dropzone .message-label {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 0 0 12px;
  }

	/* Multiple File Upload Dropzone */

	.tasks .multiple-file-upload-dropzone .base {
    height: 121px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled{
    height: 65px;
    margin-bottom: 20px;
  }

  .tasks .multiple-file-upload-dropzone .base.filled.reject + .message-label {
    top: 65px;
  }

  .tasks .multiple-file-upload-dropzone .label-title {
    font-size: 14px;
    line-height: 21px;
  }
  
  .tasks .multiple-file-upload-dropzone .label-subtitle {
    font-size: 10px;
    line-height: 18px;
    margin-top: 2px;
  }

  .tasks .multiple-file-upload-dropzone .image-container {
    margin-bottom: 8px
  }

  .tasks .multiple-file-upload-dropzone .message-label {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 0 0 12px;
  }

  .tasks .multiple-file-upload-dropzone .file-item:not(:last-of-type) {
    margin-bottom: 12px
  }

	/* File Item */

	.tasks .file-item {
    padding: 13px 17px;
  }

  .tasks .file-item .gray-document {
    height: 28px;
    width: 22px;
  }

  .tasks .file-item .file-label-container{
    margin-left: 9px;
  }

  .tasks .file-item .file-name {
    font-size: 12px;
    line-height: 18px;
    width: 160px;
  }
  
  .tasks .file-item .file-size {
    font-size: 8px;
    line-height: 12px;
  }
  
  .tasks .file-item .delete-button {
    width: 11.67px;
    height: 15px;
  }

  /* Form Modal */

  .tasks .modal-form-container {
    width: 288px;
    padding: 16px;
  }

  .tasks .modal-form-container .modal-exit-button {
    width: 24px;
    height: 24px;
    margin-bottom: 16px;
  }

  .tasks .modal-form-container .modal-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .tasks .modal-form-container .modal-subtitle {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .tasks .modal-form-container .modal-input-container {
    margin-bottom: 16px;
  }

  .tasks .modal-form-container .modal-submit-button {
    font-size: 14px;
    line-height: 21px;
    height: 48px;
  }

}
