.app-bar {
	position: fixed;
	background: white;
	z-index: 999;
	width: calc(100% - 64px);
}

.offset {
	height: 72px;
}

.app-bar .app-bar-container {
	display: flex;
	justify-content: space-between;
	min-height: 72px;
	font-size: 20px;
	font-weight: bold;
	padding-right: 32px;
}

.app-bar .app-bar-left,
.app-bar .app-bar-right {
	display: flex;
	align-items: center;
}

.app-bar .logo {
	width: 182px;
	margin-right: 82px;
	cursor: pointer;
}

.app-bar .menu,
.app-bar .menu-nav,
.app-bar .dropdown {
	display: flex;
	align-items: center;
}

.app-bar .menu-nav {
	margin-right: 56px;
}

.app-bar .menu,
.app-bar .auth,
.app-bar .switch {
	margin-top: 3px;
}

.app-bar .auth {
	color: var(--primary-color);
	cursor: pointer;
}

.app-bar .switch {
	margin-right: 24px;
}

@media only screen and (max-width: 1279px) {
	.app-bar .app-bar-container {
		min-height: 64px;
	}

	.offset {
		height: 64px;
	}

	.app-bar .menu,
	.app-bar .auth,
	.app-bar .switch {
		display: none;
	}

	.app-bar .logo {
		margin-right: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.app-bar {
		padding: 0px 16px;
		width: calc(100% - 32px);
	}

	.app-bar .app-bar-container {
		min-height: 48px;
	}

	.offset {
		height: 48px;
	}

	.app-bar .logo {
		width: auto;
	}

	.app-bar .logo,
	.app-bar .mobile-menu-icon {
		height: 24px;
	}
}
