.app-bar-menu-list {
	cursor: pointer;
}

.app-bar-menu-list ul li {
	padding: 16px;
	font-size: 20px;
}

.app-bar-menu-list .popper {
	top: 10px !important;
	left: 7px !important;
}

.app-bar-menu-list .menu-list-arrow {
	padding-left: 5px;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}

.app-bar-menu-list .menu-list-arrow.open {
	-moz-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.app-bar-menu-list .MuiButtonBase-root {
	cursor: initial;
}
