.textarea-custom {
  position: relative;
}

.textarea-custom textarea,
.textarea-custom textarea::placeholder {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}

.textarea-custom textarea {
  width: 100%;
  resize: none;
  padding: 18px 16px;
  border: 1px solid #767676;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--black);
  background-color: white;
}

.textarea-custom .message {
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
  top: calc(100% - 3px);
  left: 16px;
}

.textarea-custom textarea::placeholder {
  color: #767676;
}

.textarea-custom textarea:hover,
.textarea-custom textarea:focus {
  border-color: var(--primary-color);
}

.textarea-custom textarea:hover::placeholder {
  color: var(--primary-color);
}

/* DISABLED STATE */

.textarea-custom textarea:disabled {
  border: 1px solid var(--light-gray);
  background-color: white;
}

.textarea-custom textarea:disabled::placeholder {
  color: var(--light-gray);
}

/* ERROR STATE */

.textarea-custom.error textarea {
  border: 1px solid var(--danger);
}

.textarea-custom.error textarea::placeholder {
  color: var(--danger);
}

.textarea-custom.error .message {
  color: var(--danger);
}

@media only screen and (max-width: 767px) {
  .textarea-custom textarea {
    padding: 17px 16px;
  }

  .textarea-custom textarea,
  .textarea-custom textarea::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .textarea-custom .message {
    font-size: 12px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .textarea-custom textarea {
    padding: 13px 12px 12px 12px;
    border-radius: 4px;
  }

  .textarea-custom textarea,
  .textarea-custom textarea::placeholder {
    font-size: 14px;
    line-height: 21px;
  }

  .textarea-custom .message {
    font-size: 10px;
    line-height: 15px;
    left: 12px;
  }
}