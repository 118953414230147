.tasks-additional-household .radio-container {
  margin-bottom: 4px;
}

.tasks-additional-household .radio-button:not(:last-child) {
  margin-bottom: 16px;
}

@media only screen and (max-width: 600px) { 
  .tasks-additional-household .radio-container {
    margin-bottom: 8px;
  }
  .tasks-additional-household .radio-button:not(:last-child) {
    margin-bottom: 4px;
  }

}
