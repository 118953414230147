.radio-switch {
  display: flex;
}

.radio-switch .radio-switch-container {
  position: relative;
  width: 100%;
}

.radio-switch .radio-switch-container:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

.radio-switch .radio-switch-container:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.radio-switch label {
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
  background: #E6F4F0;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.radio-switch input[type=radio] {
  display: none;
}

.radio-switch input[type=radio]:checked + label {
  background: var(--primary-color);
  color: #FFFFFF;
}

@media only screen and (max-width: 767px) {
  .radio-switch label {
    font-size: 16px;
    line-height: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .radio-switch label {
    font-size: 14px;
    line-height: 21px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}