/* NORMAL STATE */

.price-field {
  position: relative;
  box-sizing: border-box;
}

.price-field .calc-field {
  padding: 18px 16px 18px 52px;
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  border: 1px solid #767676;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.price-field .adornment {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  font-size: 20px;
  line-height: 30px;
  color: #767676;
}

.price-field .message {
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  color: #767676;
  left: 16px;
  top: 68px;
}

/* ACTIVE STATE */

.price-field .calc-field:focus,
.price-field .calc-field:hover {
  border: 1px solid var(--primary-color);
}

.price-field .calc-field:focus + .adornment,
.price-field .calc-field:hover + .adornment {
  color: var(--primary-color);
}

/* DISABLED STATE */

.price-field .calc-field:disabled {
  background-color: #ffffff;
  border: 1px solid var(--light-gray);
}

.price-field .calc-field:disabled + .adornment{
  color: var(--light-gray) !important;
}

/* ERROR STATE */

.price-field.error .calc-field {
  border: 1px solid var(--danger);
}

.price-field.error .adornment {
  color: var(--danger) !important;
}

.price-field.error .message {
  color: var(--danger)
}

@media only screen and (max-width: 767px) {
  .price-field .calc-field {
    padding: 17px 16px 17px 47px;
    font-size: 16px;
    line-height: 24px;
  }

  .price-field .adornment {
    font-size: 16px;
    line-height: 24px;
  }

  .price-field .message {
    font-size: 12px;
    line-height: 18px;
    top: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .price-field .calc-field {
    padding: 12px 12px 13px 37px;
    font-size: 14px;
    line-height: 21px;
    border-radius: 4px;
  }

  .price-field .adornment {
    font-size: 14px;
    line-height: 21px;
    left: 12px;
  }

  .price-field .message {
    font-size: 10px;
    line-height: 15px;
    top: 48px;
    left: 12px;
  }
}