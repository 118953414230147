.title {
	font-size: 40px;
	line-height: 48px;
	font-weight: 700;
	color: var(--primary-color);
	margin-bottom: 24px;
}

.title-text {
	margin-right: 24px;
	display: inline-block;
}

.line {
	height: 24px;
	width: 80px;
	border-bottom: solid 2px var(--primary-color);
	display: inline-block;
	vertical-align: top;
}

@media only screen and (max-width: 767px) {
	.title {
		font-size: 20px;
		line-height: 30px;
	}

	.title-text {
		margin-right: 12px;
	}

	.line {
		height: 15px;
		width: 40px;
		border-bottom: solid 1px;
	}
}
