.property-preapproval .address-summary {
  border: 2px solid var(--primary-color);
  background: #F5FFFC;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 48px;
  display: flex;
}

.property-preapproval .green-marker {
  width: 32px;
  height: 32px;
}

.property-preapproval .address-summary-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--dark-gray-text);
  margin-left: 16px;
}

.property-preapproval .title {
  margin-bottom: 32px;
}

.property-preapproval .field-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--black);
  margin-bottom: 24px;
}

.property-preapproval .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.property-preapproval .fields-container {
  margin-top: 48px;
}

.property-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .property-preapproval .address-summary {
    padding: 16px;
  }

  .property-preapproval .green-marker {
    width: 24px;
    height: 24px;
  }

  .property-preapproval .address-summary-text {
    font-size: 16px;
    line-height: 21px;
  }

  .property-preapproval .field-title {
    font-size: 20px;
    line-height: 30px;
  }

  .property-preapproval .fields-container {
    margin-top: 32px;
  }

  .property-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .property-preapproval .address-summary {
    border: 1px solid var(--primary-color);
    padding: 8px;
    margin-bottom: 24px;
  }

  .property-preapproval .green-marker {
    width: 20px;
    height: 20px;
  }

  .property-preapproval .address-summary-text {
    font-size: 12px;
    line-height: 18px;
    margin-left: 8px;
  }

  .property-preapproval .title {
    margin-bottom: 24px;
  }

  .property-preapproval .field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  .property-preapproval .custom-radio-container {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .property-preapproval .fields-container {
    margin-top: 24px;
  }

  .property-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }
}