.base {
	padding: 0px 32px 0px;
	display: flex;
	justify-content: center;
}

.base-container {
	max-width: 1440px;
	width: 100%;
}

@media only screen and (max-width: 767px) {
	.base {
		padding: 0px 16px;
	}
}
