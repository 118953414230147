.mobile-menu {
	margin-right: 28px;
	display: none;
}

.mobile-menu .mobile-menu-icon {
	color: var(--primary-color);
}

.drawer-menu {
	position: relative;
}

.drawer-menu .MuiBackdrop-root {
	background-color: var(--primary-color);
	opacity: 0.4 !important;
}

.drawer-menu .MuiPaper-root {
	width: 296px;
	padding: 16px 0px;
	background-color: var(--primary-color);
}

.drawer-menu .logo-white {
	margin-bottom: 20px;
	padding: 0px 32px;
	cursor: pointer;
}

.drawer-menu .switch-wrapper {
	position: absolute;
	bottom: calc(33px + 16px);
	right: 16px;
}

@media only screen and (max-width: 1279px) {
	.mobile-menu {
		margin-right: 20px;
		display: block;
	}

	.mobile-menu .mobile-menu-icon {
		display: block;
		top: -2px;
		position: relative;
		cursor: pointer;
	}
}

@media only screen and (max-width: 767px) {
	.mobile-menu {
		margin-right: 16px;
	}

	.drawer-menu .MuiPaper-root {
		width: 208px;
	}

	.drawer-menu .logo-white {
		padding: 0px 16px;
	}
}
