.contact-preapproval .input-container:not(:last-child) {
  margin-bottom: 36px;
}

.contact-preapproval .MuiCircularProgress-colorPrimary {
  color: #ffffff;
  width: 30px !important;
  height: 30px !important;
}

@media only screen and (max-width: 767px) {
  .contact-preapproval .input-container:not(:last-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .contact-preapproval .input-container:not(:last-child) {
    margin-bottom: 24px;
  }

  .contact-preapproval .MuiCircularProgress-colorPrimary {
    width: 21px !important;
    height: 21px !important;
  }
}