.button.MuiButton-root {
	text-transform: initial;
	border-radius: 8px;
	height: 60px;
	font-weight: 700;
	font-size: 20px;
}

.button.MuiButton-outlined {
	color: var(--primary-color);
	border-color: var(--primary-color);
}

.button.MuiButton-outlined.Mui-disabled {
	opacity: .4;
	color: var(--black);
}

.button.MuiButton-contained {
	background-color: var(--primary-color);
	color: white;
}

.button.MuiButton-contained.Mui-disabled {
	background-color: var(--primary-color);
	opacity: .4;
	color: white;
}

.button.MuiButton-contained:hover {
	box-shadow: 0px 8px 24px rgba(47, 166, 127, 0.16);
	background-color: var(--primary-color);
}

@media only screen and (max-width: 767px) {
	.button.MuiButtonBase-root {
		height: 48px;
		font-size: 16px;
	}
}