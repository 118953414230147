.toast {
	box-sizing: border-box;
	position: fixed;
  z-index: 999999;
  top: 0px;
  left: 0px;
}

.toast .toast-item {
	background: #FF5858;
  opacity: 0;
  display: none;
	pointer-events: auto;
	overflow: hidden;
	width: 100vw;
  color: #ffffff;
  padding: 16px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  box-sizing: border-box;
}

.toast .toast-item.success {
  background: var(--primary-color);
}

.toast span {
  font-weight: bold;
}

.toast .animate-enter {
  opacity: 0;
  display: block;
  transform: translateY(-150px);
}
.toast .animate-enter-active {
  opacity: 1;
  display: block;
  transform: translateY(0);
  transition: transform 500ms, opacity 300ms;
}
.toast .animate-enter-done {
  opacity: 1;
  display: block;
}
.toast .animate-exit {
  opacity: 1;
  display: block;
  transform: translateY(0);
}
.toast .animate-exit-active {
  opacity: 0;
  display: block;
  transform: translateY(-150px);
  transition: transform 500ms, opacity 300ms;
}

@media only screen and (max-width: 767px) {
  .toast .toast-item {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .toast .toast-item {
    font-size: 14px;
    line-height: 21px;
    padding: 8px;
  }
}