.tasks-panel {
	border: 1px solid var(--light-gray);
	border-radius: 8px;
	color: var(--dark-gray-text);
	margin-bottom: 48px;
}

.tasks-panel .title {
	padding: 24px 32px;
	color: var(--dark-gray-text);
	font-size: 20px;
	font-weight: 700;
	border-bottom: 1px solid var(--light-gray);
	margin-bottom: 0px;
}

@media only screen and (max-width: 1080px) {
	.tasks-panel {
		margin-bottom: 40px;
	}

	.tasks-panel .title {
		padding: 20px 24px;
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.tasks-panel {
		margin-bottom: 24px;
	}

	.tasks-panel .title {
		padding: 12px 19px;
		font-size: 14px;
	}
}
