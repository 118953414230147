@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
	font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
	color: #000000;
	margin: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: var(--black);
	text-decoration: none;
}

.hr {
	width: 100%;
	opacity: 0.4;
	border: solid 0.25px white;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
}

.highlight {
	color: var(--primary-color) !important;
	font-weight: 900 !important;
}

* {
	margin: 0;
	padding: 0;
	line-height: 150%;
	-webkit-tap-highlight-color: transparent;
}

*:focus {
	outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
	box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

textarea, 
input[type=text], 
input[type=password], 
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
