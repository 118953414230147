.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(149, 149, 149, 0.58);
}

.modal-content {
  position: fixed;
  background-color: #ffffff;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  box-sizing: border-box;
}

/* --------------- MODAL FORM ---------------- */

.modal-form-container {
  padding: 32px;
  box-sizing: border-box;
  width: 600px;
}

.modal-form-container .modal-exit-button {
  width: 40px;
  height: 40px;
  margin-bottom: 32px;
  cursor: pointer;
}

.modal-form-container .modal-title {
  /* margin-top: 24px; */
  font-weight: bold;
  font-size: 40px;
  line-height: 58px;
  color: var(--black);
  margin-bottom: 32px;
}

.modal-form-container .modal-subtitle {
  font-size: 24px;
  line-height: 36px;
  color: var(--dark-gray-text);
  margin-bottom: 32px;
}

.modal-form-container .modal-input-container {
  margin-bottom: 24px;
}

.modal-form-container .modal-submit-button {
  width: 100%;
  height: 60px;
}

@media only screen and (max-width: 767px) {
  .modal-form-container {
    width: 481px;
  }

  .modal-form-container .modal-exit-button {
    width: 32px;
    height: 32px;
    margin-bottom: 24px;
  }

  .modal-form-container .modal-title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  .modal-form-container .modal-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  .modal-form-container .modal-submit-button {
    font-size: 20px;
    line-height: 30px;
    height: 60px;
  }
}

@media only screen and (max-width: 600px) {
  .modal-form-container {
    width: 288px;
    padding: 16px;
  }

  .modal-form-container .modal-exit-button {
    width: 24px;
    height: 24px;
    margin-bottom: 16px;
  }

  .modal-form-container .modal-title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .modal-form-container .modal-subtitle {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }

  .modal-form-container .modal-input-container {
    margin-bottom: 16px;
  }

  .modal-form-container .modal-submit-button {
    font-size: 14px;
    line-height: 21px;
    height: 48px;
  }
}