.file-upload-dropzone .base {
  height: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F4F4F4;
  border: 1px dashed var(--light-gray-text);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.file-upload-dropzone .base.reject {
  background: #FFECEC;
  border: 1px dashed #D65B5B;
}

.file-upload-dropzone .base.accept {
  background: #F8FEFC;
  border: 1px dashed var(--primary-color);
}

.file-upload-dropzone .label-title {
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #767676;
}

.file-upload-dropzone .label-subtitle {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #767676;
  margin-top: 8px;
}

.file-upload-dropzone .image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.file-upload-dropzone .drag-and-drop-image {
  width: 32px;
  height: 32px;
}

.file-upload-dropzone .message-label {
  position: absolute;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 0 16px;
}

.file-upload-dropzone .message-label.reject {
  color: var(--danger); 
}


@media only screen and (max-width: 767px) {
  .file-upload-dropzone .base {
    height: 206px;
  }

  .file-upload-dropzone .label-title {
    font-size: 16px;
    line-height: 24px;
  }
  
  .file-upload-dropzone .label-subtitle {
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
  }

  .file-upload-dropzone .image-container {
    margin-bottom: 19px
  }

  .file-upload-dropzone .drag-and-drop-image {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .file-upload-dropzone .base {
    height: 121px;
  }

  .file-upload-dropzone .label-title {
    font-size: 14px;
    line-height: 21px;
  }
  
  .file-upload-dropzone .label-subtitle {
    font-size: 10px;
    line-height: 18px;
    margin-top: 2px;
  }

  .file-upload-dropzone .image-container {
    margin-bottom: 8px
  }

  .file-upload-dropzone .message-label {
    font-size: 10px;
    line-height: 15px;
    margin: 0px 0 0 12px;
  }
}