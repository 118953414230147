.checkbox {
  display: flex;
  align-items: center;
  position: relative;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox .checkmark {
  border: 2px solid #2FA67F;
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.checkbox input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 8px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox .checkbox-label {
  font-size: 20px;
  line-height: 30px;
  color: var(--black);
  margin-left: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .checkbox .checkmark {
    width: 20px;
    height: 20px;
  }

  .checkbox .checkmark:after {
    left: 7.5px;
    top: 6px;
    width: 3px;
    height: 8px;
    border-width: 0 2px 2px 0;
  }

  .checkbox .checkbox-label {
    font-size: 16px;
    line-height: 24px;
    margin-left: 12px;
  }
}