.is-private {
	color: var(--primary-color);
	background-color: #f8fefc;
	border-color: #2fa67f;
}

.is-private .text {
	font-weight: 700;
	margin-bottom: 0px;
}

.is-private .icon-container {
	position: relative;
	top: 6px;
}

.is-private .icon-container svg {
	height: 20px;
}

.is-private {
	margin-bottom: 40px;
}

@media only screen and (max-width: 1080px) {
  .is-private .icon-container {
    top: 2px;
  }
}

@media only screen and (max-width: 600px) {
  .is-private .icon-container {
    top: 0px;
  }
}