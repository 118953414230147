.tasks-helper {
  margin-bottom: 48px;
}

.tasks-helper .text {
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-gray-text);
}

.tasks-helper .highlight {
  color: #2FA67F;
  cursor: pointer;
  font-weight: 700 !important;
}

.tasks-helper .item:not(:last-child) {
  margin-bottom: 12px
}

@media only screen and (max-width: 1080px) {
  .tasks-helper {
    margin-bottom: 32px;
  }

  .tasks-helper .text {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .tasks-helper {
    margin-bottom: 24px;
  }

  .tasks-helper .text {
    font-size: 12px;
    line-height: 21px;
  }

  .tasks-helper .item:not(:last-child) {
    margin-bottom: 8px
  }
}