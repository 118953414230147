.marital-preapproval .custom-radio-container > div:not(:last-child) {
  margin-bottom: 24px;
}

.marital-preapproval .input-container {
  margin-top: 32px;
}

.marital-preapproval .children-field-container {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .marital-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 16px;
  }

  .marital-preapproval .input-container {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .marital-preapproval .custom-radio-container > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .marital-preapproval .children-field-container {
    margin-top: 24px;
  }
}