.verification-modal {
	padding: 129px 0px;
}

.verification-modal .verification-modal-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.verification-modal .check-icon {
	color: var(--primary-color);
	font-size: 136px;
	text-align: center;
	margin-bottom: 48px;
}

.verification-modal .title {
	color: var(--black);
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 16px;
}

.verification-modal .description {
	color: var(--dark-gray-text);
	font-size: 24px;
	margin-bottom: 64px;
}

.verification-modal button.continue {
	width: 536px;
	height: 68px;
}

@media only screen and (max-width: 1080px) {
	.verification-modal {
		padding: 330px 0px;
	}

	.verification-modal .check-icon {
		margin-bottom: 40px;
		font-size: 96px;
	}

	.verification-modal .title {
		margin-bottom: 12px;
		font-size: 32px;
	}

	.verification-modal .description {
		font-size: 20px;
		margin-bottom: 48px;
	}

	.verification-modal button.continue {
		width: 417px;
		height: 60px;
	}
}

@media only screen and (max-width: 600px) {
	.verification-modal {
		padding: 136px 15px;
	}

	.verification-modal .check-icon {
		margin-bottom: 82px;
		font-size: 72px;
	}

	.verification-modal .title {
		font-size: 20px;
	}

	.verification-modal .description {
		font-size: 14px;
	}

	.verification-modal button.continue {
		width: 288px;
		height: 48px;
	}
}
