.overview-header {
	font-weight: 900;
	margin-bottom: 80px;
}

.overview-header .sub-header {
	color: var(--black);
	text-transform: uppercase;
	margin-bottom: 8px;
	font-size: 16px;
	line-height: 24px;
}

.overview-header .header {
	font-size: 56px;
	line-height: 68px;
}

@media only screen and (max-width: 1080px) {
	.overview-header {
		margin-bottom: 64px;
	}

	.overview-header .sub-header {
		margin-bottom: 4px;
	}

	.overview-header .header {
		font-size: 44px;
		line-height: 57px;
	}
}

@media only screen and (max-width: 600px) {
	.overview-header {
		margin-bottom: 32px;
	}

	.overview-header .sub-header {
		font-size: 12px;
		margin-bottom: 0px;
	}

	.overview-header .header {
		font-size: 24px;
		line-height: 36px;
	}
}
