.language-switcher {
	display: flex;
	background: white;
	padding: 2px;
	width: 92px;
	box-sizing: border-box;
	border-radius: 4px;
}

.language-switcher .language-switcher-container {
	position: relative;
}

.language-switcher .language-switcher-container {
	border-radius: 4px;
}

.language-switcher label {
	display: inline-block;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: var(--primary-color);
	cursor: pointer;
	padding: 4px 0px;
	width: 44px;
	box-sizing: border-box;
	background: white;
	text-align: center;
	border-radius: 4px;
	transition: background 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
		color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.language-switcher label.active {
	background: var(--primary-color);
	color: #ffffff;
}
